const middleware = {}

middleware['activeRegister'] = require('../middleware/activeRegister.js')
middleware['activeRegister'] = middleware['activeRegister'].default || middleware['activeRegister']

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['appointments/configuration'] = require('../middleware/appointments/configuration.js')
middleware['appointments/configuration'] = middleware['appointments/configuration'].default || middleware['appointments/configuration']

middleware['appointments/operator'] = require('../middleware/appointments/operator.js')
middleware['appointments/operator'] = middleware['appointments/operator'].default || middleware['appointments/operator']

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['citas/isAdminAppt'] = require('../middleware/citas/isAdminAppt.js')
middleware['citas/isAdminAppt'] = middleware['citas/isAdminAppt'].default || middleware['citas/isAdminAppt']

middleware['completedata'] = require('../middleware/completedata.js')
middleware['completedata'] = middleware['completedata'].default || middleware['completedata']

middleware['dashboard'] = require('../middleware/dashboard.js')
middleware['dashboard'] = middleware['dashboard'].default || middleware['dashboard']

middleware['hasallinfo'] = require('../middleware/hasallinfo.js')
middleware['hasallinfo'] = middleware['hasallinfo'].default || middleware['hasallinfo']

middleware['hasUpdatedPassword'] = require('../middleware/hasUpdatedPassword.js')
middleware['hasUpdatedPassword'] = middleware['hasUpdatedPassword'].default || middleware['hasUpdatedPassword']

middleware['inspectores/isAdminInspector'] = require('../middleware/inspectores/isAdminInspector.js')
middleware['inspectores/isAdminInspector'] = middleware['inspectores/isAdminInspector'].default || middleware['inspectores/isAdminInspector']

middleware['inspectores/operator'] = require('../middleware/inspectores/operator.js')
middleware['inspectores/operator'] = middleware['inspectores/operator'].default || middleware['inspectores/operator']

middleware['module'] = require('../middleware/module.js')
middleware['module'] = middleware['module'].default || middleware['module']

middleware['operatorAndEditorNotAllowed'] = require('../middleware/operatorAndEditorNotAllowed.js')
middleware['operatorAndEditorNotAllowed'] = middleware['operatorAndEditorNotAllowed'].default || middleware['operatorAndEditorNotAllowed']

middleware['operatorNotAllowed'] = require('../middleware/operatorNotAllowed.js')
middleware['operatorNotAllowed'] = middleware['operatorNotAllowed'].default || middleware['operatorNotAllowed']

middleware['payments/isAdmin'] = require('../middleware/payments/isAdmin.js')
middleware['payments/isAdmin'] = middleware['payments/isAdmin'].default || middleware['payments/isAdmin']

middleware['payments/isTesorero'] = require('../middleware/payments/isTesorero.js')
middleware['payments/isTesorero'] = middleware['payments/isTesorero'].default || middleware['payments/isTesorero']

middleware['permitModule'] = require('../middleware/permitModule.js')
middleware['permitModule'] = middleware['permitModule'].default || middleware['permitModule']

middleware['preventiveDelivery/isAdminPrev'] = require('../middleware/preventiveDelivery/isAdminPrev.js')
middleware['preventiveDelivery/isAdminPrev'] = middleware['preventiveDelivery/isAdminPrev'].default || middleware['preventiveDelivery/isAdminPrev']

middleware['preventiveDelivery/operator'] = require('../middleware/preventiveDelivery/operator.js')
middleware['preventiveDelivery/operator'] = middleware['preventiveDelivery/operator'].default || middleware['preventiveDelivery/operator']

middleware['procedureRequest'] = require('../middleware/procedureRequest.js')
middleware['procedureRequest'] = middleware['procedureRequest'].default || middleware['procedureRequest']

middleware['registerType'] = require('../middleware/registerType.js')
middleware['registerType'] = middleware['registerType'].default || middleware['registerType']

middleware['submodule'] = require('../middleware/submodule.js')
middleware['submodule'] = middleware['submodule'].default || middleware['submodule']

middleware['superadmin'] = require('../middleware/superadmin.js')
middleware['superadmin'] = middleware['superadmin'].default || middleware['superadmin']

middleware['theme'] = require('../middleware/theme.js')
middleware['theme'] = middleware['theme'].default || middleware['theme']

middleware['unauthenticated'] = require('../middleware/unauthenticated.js')
middleware['unauthenticated'] = middleware['unauthenticated'].default || middleware['unauthenticated']

export default middleware
