
import { mapGetters } from 'vuex'
import { logOut } from '@/services/Firebase'

export default {
  data() {
    return {
      show: false,
      timerCount: 60,
      timerEnabled: false,
      logoutTimeout: setTimeout(() => {}, 0),
    }
  },

  computed: {
    ...mapGetters({
      brand: 'brand/brand',
    }),
  },

  watch: {
    show(value) {
      if (value === true) {
        this.timerEnabled = true
        this.logoutWithTimeout()
      } else {
        this.reset()
      }
    },
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--
        }, 1000)
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    const self = this
    this.unsubscribe = await this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'dialogIdle/SHOW_DIALOG') {
        this.show = state.dialogIdle.show
      } else if (mutation.type === 'dialogIdle/CLOSE_DIALOG') {
        self.show = state.dialogIdle.show
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe()
  },

  methods: {
    logoutWithTimeout() {
      this.logoutTimeout = setTimeout(async () => {
        await this.logout()
        this.$store.dispatch('dialogIdle/close')
      }, 60000)
    },
    reset() {
      this.$store.dispatch('dialogIdle/close')
      this.timerEnabled = false
      this.timerCount = 60
      clearTimeout(this.logoutTimeout)
    },
    async logout() {
      try {
        const self = this
        await logOut(this.$fire)
        this.$store.dispatch('dialogIdle/close')
        const reino = localStorage.getItem('reino')
        if (reino) {
          await self.$autenticar.logout()
          localStorage.removeItem('reino')
          localStorage.removeItem('backToReino')
          localStorage.removeItem('levelUpdated')
        }
        await this.$store.commit('users/LOGOUT_USER')
        await this.$store.dispatch('users/fetchUser')
        this.$store.dispatch('setloggedIn', false)
        this.$router.push({ path: '/' })
      } catch (error) {
        this.snackbar.show = true
        this.snackbar.text = 'Ocurrió un error al cerrar sesión'
        this.snackbar.color = 'error'
      }
    },
  },
}
