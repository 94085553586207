import Vue from 'vue'
import IdleVue from 'idle-vue'

const eventsHub = new Vue()

export default function (context) {
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store: context.store,
    idleTime: 600000,
    startAtIdle: false,
  })
}
