export const getAllPaymentMethods = async ($fire: any) => {
  return await $fire.firestore
    .collection('tools')
    .doc('sales')
    .collection('methods')
    .get()
}
export const getActivatePaymentMethods = async ($fire: any) => {
  const methods = [] as any
  await $fire.firestore
    .collection('tools')
    .doc('sales')
    .collection('methods')
    .where('active', '==', true)
    .get()
    .then((querySnapshot: any) => {
      querySnapshot.forEach(function (doc: any) {
        //  Se asegura que el array no contenga el nombre del formulario actual
        methods.push({
          title: doc.data().carrier + ' - ' + doc.data().dependency,
          id: doc.id,
          carrier: doc.data().carrier,
          dependency: doc.data().dependency,
          clientID: doc.data().clientID,
        })
      })
    })
  return methods
}

export const getAllPaymentConcepts = async ($fire: any, filter: any) => {
  const concepts = [] as any
  let query = await $fire.firestore
    .collection('tools')
    .doc('sales')
    .collection('concepts')
    .orderBy('createdAt', 'desc')
  if (filter && filter.length > 0) {
    filter.forEach((item: any) => {
      query = query.where(`${item.key}`, `${item.type}`, `${item.value}`)
    })
  }
  query.get().then((querySnapshot: any) => {
    querySnapshot.forEach(function (doc: any) {
      //  Se asegura que el array no contenga el nombre del formulario actual
      concepts.push({
        ...doc.data(),
        id: doc.id,
      })
    })
  })
  return concepts
}

export const getAllPaymentUMAS = async ($fire: any) => {
  return await $fire.firestore
    .collection('tools')
    .doc('sales')
    .collection('umas')
    .orderBy('year', 'desc')
    .orderBy('createdAt', 'desc')
    .get()
}
export const getCurrentUMA = async ($fire: any, year: any) => {
  const isArg =
    process.env.FB_PROJECT_ID === 'os-lujan-de-cuyo' ||
    process.env.FB_PROJECT_ID === 'os-arg-lujan-de-cuyo'
  if (isArg === true) {
    return await $fire.firestore
      .collection('tools')
      .doc('sales')
      .collection('umas')
      .orderBy('year', 'desc')
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get()
  } else {
    return await $fire.firestore
      .collection('tools')
      .doc('sales')
      .collection('umas')
      .where('year', '==', parseFloat(year))
      .limit(1)
      .get()
  }
}
