export const getFullName = function (firstName, lastName, lastName2 = '') {
  if (firstName && lastName) {
    return firstName + ' ' + lastName + (lastName2 ? ' ' + lastName2 : '')
  } else if (firstName) {
    return firstName
  } else {
    return ''
  }
}

export const sliceArrayIntoChunks = function (arr, chunkSize) {
  const res = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize)
    res.push(chunk)
  }
  return res
}

export const isTokenExpired = (token) => {
  const decodedToken = JSON.parse(atob(token.split('.')[1]))
  const expirationTime = decodedToken.exp
  const currentTime = Math.floor(Date.now() / 1000) // Convertimos a segundos

  return expirationTime < currentTime
}

export const getBrowserInfo = () => {
  const userAgent = navigator.userAgent
  let browserName = 'Unknown'

  if (userAgent.includes('Firefox')) {
    browserName = 'Firefox'
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    browserName = 'Opera'
  } else if (userAgent.includes('Trident')) {
    browserName = 'Internet Explorer'
  } else if (userAgent.includes('Edge')) {
    browserName = 'Edge'
  } else if (userAgent.includes('Chrome')) {
    browserName = 'Chrome'
  } else if (userAgent.includes('Safari')) {
    browserName = 'Safari'
  }

  return browserName
}
